:host ::ng-deep .ck-editor__editable_inline {
    min-height: 400px;
  }

  .xyz{
    min-height: 400px;
  }

  /* loader starts */
.overlayDiv {
  width: 100vw;
  height: 100vh;
  display: block;
  position: fixed;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  -moz-backdrop-filter: blur(5px);
}
.pleaseWait{
  position: absolute;
  top: 57%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.loader {
  position: absolute;
  top: calc(50% - 6vmax);
  left: calc(50% - 4vmax);
  width: 8vmax;
  height: 8vmax;
  border-right: 4px solid #ffffff;
  border-radius: 100%;
  animation: spinRight 1s linear infinite;
}
    
.loader:before{
  content: '';
  width: 6vmax;
  height: 6vmax;
  display: block;
  position: absolute;
  top: calc(50% - 3vmax);
  left: calc(50% - 3vmax);
  border-left: 3px solid #ffffff;
  border-radius: 100%;
  animation: spinLeft 1s linear infinite;
}
    
.loader:after {
  content: '';
  width: 6vmax;
  height: 6vmax;
  display: block;
  position: absolute;
  top: calc(50% - 3vmax);
  left: calc(50% - 3vmax);
  border-left: 3px solid #ffffff;
  border-radius: 100%;
  animation: spinLeft 800ms linear infinite;
}
  
@keyframes spinLeft {
  from {transform:rotate(0deg);}
  to {transform:rotate(720deg);}
}

@keyframes spinRight {
  from {transform:rotate(360deg);}
  to {transform:rotate(0deg);}
}

/* loader end */




/* tarun */
.logo-white .navbar-header {
  background-color: #efefef;
}
.navbar-nav .user_profile img.rounded-circle {
  box-shadow: none;
}
.menu .material-icons-two-tone {
  font-size: 28px;
}
.submenu-closed .headlogo  img.two{display: none !important ;}
.submenu-closed .headlogo img.one {
  margin-right: 0!important;
  max-width: 39px;
  width: 100%;
}
.sidebar .menu .list {
  padding-top: 40px;
}
.theme-white .sidebar .menu .list li a {
  background: rgb(255 255 255);
  background: linear-gradient(86deg, rgb(255 255 255 / 8%) 0%, hsl(0deg 0% 100%) 51%, rgb(253 187 45 / 9%) 100%);
}
.submenu-closed .menu .material-icons-two-tone {
  font-size: 24px;
}
.theme-white.submenu-closed .sidebar .menu .list li a {
  background: rgb(255, 255, 255);
}
.sidebar .menu .list .ml-menu li a {
  padding-left: 44px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px!important;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #f7c548;
}
.breadcrumb .page-title {
  color: #01a9f4;
}
.card {
  background-color: #fff;
  border-radius: 0;
  position: relative;
  margin-bottom: 30px;
  border: 2px solid #f7c548;
}
.card .header {
  padding: 14px 26px;
  background-color: #f7c548;
}
.card .body {
  padding: 24px;
}
.card .header h2, .card .header .h2 {
  color: #000000;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  text-transform: capitalize;
}
.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 26px;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 9px 0;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}
.card .body .col-xs-6, .card .body .col-sm-6, .card .body .col-md-6, .card .body .col-lg-6 {
  margin-bottom: 0px;
}
.example-margin.ml-0{
  margin-left: 0;
}
.mat-checkbox-inner-container {
  height: 20px!important;
  width: 20px!important;
}
label, .form-check {
  font-size: 16px!important;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #1fb2f3;
}
.ck-editor__editable_inline {
  min-height: 190px;
}
.xyz {
  min-height: auto;
  margin-bottom: 18px;
}
.matlabel{
  font-size: 16px;
  font-weight: 500;
}
textarea.mat-input-element {
  height: 70px;
  resize: none;
}
.listimgbox {
  width: 23%;
  height: 94px;
  border: 1px solid #ede8e8;
  margin-top: 27px;
  margin-right: 2%;
  display: flex;
  background-color: #f5f5f5;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  position: relative;
}
.listimgbox button.tblActnBtn {
  position: absolute;
  right: 0px;
  background-color: #f7c548;
  top: 0;
  padding: 0;
  border-radius: 0;
  height: 26px!important;
}
.listimgbox button.tblActnBtn i {
  font-size: 24px;
}
.mat-raised-button {
 border-radius: 0!important;
  padding: 4px 30px!important;
  
}
.mat-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button {
  
  padding: 4px 30px!important;
  border-radius: 0!important;
}
.dataTables_wrapper .dataTables_filter {
  margin-bottom: 24px;
}
.dataTables_wrapper .dataTables_paginate {
  padding-top: 28px!important;
}
.dataTables_wrapper .dataTables_info {
  padding-top: 28px!important;
}
table.dataTable thead th {
  font-size: 16px!important;
  font-weight: 500!important;
  white-space: nowrap!important;
}
.btn-edit{
  min-width: 34px;
    max-width: 34px;
    height: 34px!important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #4758b8;
  border-radius: 50%;
  margin: 0 3px;
  color: #fff;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}
.btn-info1{
  min-width: 34px;
    max-width: 34px;
    height: 34px!important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #0dcaf0;
  border-radius: 50%;
  margin: 0 3px;
  color: #000;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}
.btn-edit i{ font-size: 18px!important;}
.btn-info1 i{ font-size: 18px!important;}
.btn-delete i{ font-size: 18px!important;}
.btn-delete{
  min-width: 34px;
  max-width: 34px;
  height: 34px!important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #dc3545;
  border-radius: 50%;
  margin: 0 3px;
  color: #fff;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.modal.show .modal-dialog {
  max-width: 530px!important;
}
.modal .modal-content {
  border-radius: 0!important;
}
.modal .modal-content .editRowModal_all .modal-body {
  color: #000;
  padding: 15px 0px;
  font-size: 16px;
}
.editRowModal_all .table-bordered tbody tr th {
 font-weight: 500;
}

body.light{
  overflow: revert !important;
}
.pac-container {
  z-index: 10000 !important;
}